import './Loading.css';

function Loading() {
    return (
        <div className="loading-body">
            <div className="image">
                <img src='/images/Loading2.svg' />
            </div>
        </div>
    );
}

export default Loading;


/*
import React from "react";
import { ClipLoader } from "react-spinners";
import "./Loading.css";

function Loading() {
    return (
        <div className="loading-spinner">
            <ClipLoader color="#36d7b7" size={50} />
            <span>Loading your data...</span>
        </div>
    );
}

export default Loading;
*/