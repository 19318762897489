export const ProductsData = [

  //Histeria Max Chocolate
  {
    Images: [
      "/images/Product1/HisteriaMaxEaten.webp",
      "/images/Product1/HisteriaMax1.webp",
      "/images/Product1/HisteriaMax2.webp",
      "/images/Product1/HisteriaMax3.webp",
    ],

    title: "Histeria Max Chocolate",
    description: "بسكويت محشي كريمة الشوكولا المغطس بالشوكولا",
    ingredients: "سكر - زيوت نباتية مهدرجة - دقيق القمح - جلوكوز - بودرة الكاكاو - كتلة الكاكاو - حليب خالي الدسم - حليب كامل الدسم - مصل الحليب - ديكستروز - ليستين الصويا E322 (مستحلب) - مواد رافعة (بيروفوسفات الصوديوم E450-1 - بيكربونات الأمونيوم - بيكربونات الصوديوم) - ملح الطعام - منكهات مماثلة للطبيعة.",
    weight: "30 غرام",
    packing: "12 علبة / طرد",
    box: "12 قطعة / علبة",
  },

  //Hugy Moosh Chocolate
  {
    Images: [
      "/images/Product2/HugyMooshDarkEaten.webp",
      "/images/Product2/HugyMooshDark1.webp",
      "/images/Product2/HugyMooshDark2.webp",
      "/images/Product2/HugyMooshDark3.webp",

    ],
    title: "Hugy Moosh Chocolate",
    description: "كيك الشوكولا مع المارشميللو المغطس بالشوكولا",
    ingredients: "سكر - زيوت نباتية مهدرجة – مياه – دقيق الطحين – جلوكوز – كاكاو طبيعي – كتلة الكاكاو – بيض - حليب خالي الدسم - حليب كامل الدسم - مصل الحليب – سوربيتول – جيلاتين بقري – نشاء معدل – ديكستروز – ليستين الصويا E322 – غلسرين – كيك جيل – بيروفوسفات الصوديوم – بيكربونات الصوديوم – ملح الطعام – نكهة فانيل – نكهة حليب – نكهة الزبدة – سوربات بوتاسيوم – بروبلين جليكول.",
    weight: "30 غرام",
    packing: "12 علبة / طرد",
    box: "12 قطعة / علبة",
  },

  //Hugy Moosh Milk
  {
    Images: [
      "/images/Product3/HugyMooshWhiteEaten.webp",
      "/images/Product3/HugyMooshWhite1.webp",
      "/images/Product3/HugyMooshWhite2.webp",
      "/images/Product3/HugyMooshWhite3.webp",
    ],
    title: "Hugy Moosh Milk",
    description: "كيك الحليب مع المارشميللو المغطس بالشوكولا",
    ingredients: "سكر - زيوت نباتية مهدرجة – مياه – دقيق الطحين – جلوكوز – كاكاو طبيعي – كتلة الكاكاو – بيض - حليب خالي الدسم - حليب كامل الدسم - مصل الحليب – سوربيتول – جيلاتين بقري – نشاء معدل – ديكستروز – ليستين الصويا E322 – غلسرين – كيك جيل – بيروفوسفات الصوديوم – بيكربونات الصوديوم – ملح الطعام – نكهة فانيل – نكهة حليب – نكهة الزبدة – سوربات بوتاسيوم – بروبلين جليكول.",
    weight: "30 غرام",
    packing: "12 علبة / طرد",
    box: "12 قطعة / علبة",
  },

  //Opaliz
  {
    Images: [
      "/images/Product4/OpalizEaten.webp",
      "/images/Product4/Opaliz1.webp",
      "/images/Product4/Opaliz2.webp",
      "/images/Product4/Opaliz3.webp",
    ],
    title: "Opaliz",
    description: "بسكويت مع الكراميل المغطس بالشوكولا",
    ingredients: "سكر - زيوت نباتية مهدرجة – دقيق الطحين – جلوكوز – بودرة الكاكاو – كتلة الكاكاو – حليب خالي الدسم – حليب كامل الدسم – مصل الحليب – جيلاتين بقري – ليستين الصويا E322 (مستحلب) – غليسرين – دكستروز – نشاء معدل – مواد رافعة (بيروفوسفات الصوديوم E450-l ، بيكربونات الصوديوم ، بيكربونات الأمونيوم) – حمض الليمون – ملح الطعام – منكهات مماثلة للطبيعة.",
    weight: "28 غرام",
    packing: "12 علبة / طرد",
    box: "12 قطعة / علبة",
  },

  //Histeria Max Milk
  {
    Images: [
      "/images/Product5/HisteriaMaxWhiteEaten.webp",
      "/images/Product5/HisteriaMaxWhite1.webp",
      "/images/Product5/HisteriaMaxWhite2.webp",
      "/images/Product5/HisteriaMaxWhite3.webp",
    ],
    title: "Histeria Max Milk",
    description: "بسكويت محشي كريم الحليب المغطس بالشوكولا",
    ingredients: "سكر - زيوت نباتية مهدرجة - دقيق القمح - جلوكوز - بودرة الكاكاو - كتلة الكاكاو - حليب خالي الدسم - حليب كامل الدسم - مصل الحليب - ديكستروز - ليستين الصويا E322 (مستحلب) - مواد رافعة (بيروفوسفات الصوديوم E450-1 - بيكربونات الأمونيوم - بيكربونات الصوديوم) - ملح الطعام - منكهات مماثلة للطبيعة.",
    weight: "30 غرام",
    packing: "12 علبة / طرد",
    box: "12 قطعة / علبة",
  },

  //Bisco Moosh
  /*
  {
    Images: [
      "/images/Product6/BiscoMooshEaten.webp",
      "/images/Product6/BiscoMoosh1.webp",
      "/images/Product6/BiscoMoosh2.webp",
      "/images/Product6/BiscoMoosh3.webp",
    ],
    title: "Bisco Moosh",
    description: "بسكويت محشي بالمارشميللو المغطس بالشوكولا",
    ingredients: "سكر - زيوت نباتية مهدرجة – دقيق الطحين – جلوكوز – كاكاو طبيعي – كتلة الكاكاو – حليب خالي الدسم – حليب كامل الدسم – مصل الحليب – سوربيتول E420 – جيلاتين بقري – ليستين الصويا E322 (مستحلب) – غلسرين – مواد رافعة (بيروفوسفات الصوديوم ، بيكربونات الأمونيوم) – مواد حافظة (سوربات البوتاسيوم E202) – ملح الطعام – منكهات مماثلة للطبيعة.",
    weight: "30 غرام",
    packing: "12 علبة / طرد",
    box: "12 قطعة / علبة",
  }
    */
];
