import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./Home.css";
import { useParams } from "react-router-dom";
import Loading from "../../Pages/Loading/Loading";

function Home() {
  const { ProductId } = useParams();
  const productIndex = parseInt(ProductId, 10);

  const [loading, setLoading] = useState(true);
  const [slides, setSlides] = useState(null);
  const [screenType, setScreenType] = useState(window.innerWidth <= 768 ? "mobile" : "desktop");

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const { HomeSliderDesktop, HomeSliderMobile } = await import("./../../Data/SliderImages");

        setSlides(screenType === "mobile" ? HomeSliderMobile : HomeSliderDesktop);
      } catch (error) {
        console.error("Error loading HomeSlider data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSlides();
  }, [screenType]); // Depend on screenType to reload only when it changes

  useEffect(() => {
    const handleResize = () => {
      const newScreenType = window.innerWidth <= 700 ? "mobile" : "desktop";
      if (newScreenType !== screenType) {
        setScreenType(newScreenType);
        setLoading(true); // Show loading while switching images
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenType]);

  if (loading) {
    return <Loading />;
  }

  if (!slides || slides.length === 0) {
    return <div>No slides available</div>;
  }

  return (
    <Swiper
      className="home-swiper"
      autoplay={{ delay: 4000 }}
      effect="fade"
      pagination={{ clickable: true, type: "bullets" }}
      style={{
        "--swiper-pagination-bullet-active": "#FFFFFF",
        "--swiper-pagination-bullet-active-color": "#ff0000",
      }}
      modules={[EffectFade, Autoplay, Pagination]}
      loop={true}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className="home-swiper-slide">
          <img src={slide.image} alt={slide.title} loading="lazy" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Home;







/*import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./Home.css";
import { useParams } from "react-router-dom";
import Loading from "../../Pages/Loading/Loading";


function Home() {
  const { ProductId } = useParams();
  const productIndex = parseInt(ProductId, 10); // Ensure it's a number

  const [loading, setLoading] = useState(true); // Track loading state
  const [slides, setSlides] = useState(null); // Store dynamically imported slides

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        // Dynamically import the HomeSlider data
        const { HomeSlider } = await import("./../../Data/SliderImages");
        setSlides(HomeSlider); // Set the loaded slides
      } catch (error) {
        console.error("Error loading HomeSlider data:", error);
      } finally {
        setLoading(false); // Loading complete
      }
    };

    fetchSlides(); // Trigger the data fetch
  }, []);

  if (loading) {
    return <Loading />; // Render loading indicator while loading
  }

  if (!slides || slides.length === 0) {
    return <div>No slides available</div>; // Fallback in case of no data
  }

  return (
    <Swiper
      className="home-swiper"
      autoplay={{ delay: 4000 }}
      effect="fade"
      pagination={{
        clickable: true,
        type: "bullets",
      }}
      style={{
        "--swiper-pagination-bullet-active": "#FFFFFF",
        "--swiper-pagination-bullet-active-color": "#ff0000",
      }}
      modules={[EffectFade, Autoplay, Pagination]}
      loop={true}
    >
      {Dynamically render SwiperSlides }
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className="home-swiper-slide">
          <img
            src={slide.image}
            alt={slide.title}
            loading="lazy"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Home;
*/













































/*
tasted but not uploaded

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./Home.css";

function Home({ slides }) {
  if (!slides || slides.length === 0) {
    return <div>No slides available</div>; // Fallback in case of no data
  }

  return (
    <Swiper
      className="home-swiper"
      autoplay={{ delay: 4000 }}
      effect="fade"
      pagination={{
        clickable: true,
        type: "bullets",
      }}
      style={{
        "--swiper-pagination-bullet-active": "#FFFFFF",
        "--swiper-pagination-bullet-active-color": "#ff0000",
      }}
      modules={[EffectFade, Autoplay, Pagination]}
      loop={true}
    >
      { Dynamically render SwiperSlides }
{
  slides.map((slide, index) => (
    <SwiperSlide key={index} className="home-swiper-slide">
      <img src={slide.image} alt={slide.title} loading="lazy" />
    </SwiperSlide>
  ))
}
    </Swiper >
  );
}

export default Home;
/*








/*
already uploaded

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./Home.css";
import { useParams } from "react-router-dom";
import Loading from "../../Pages/Loading/Loading";


function Home() {
  const { ProductId } = useParams();
  const productIndex = parseInt(ProductId, 10); // Ensure it's a number

  const [loading, setLoading] = useState(true); // Track loading state
  const [slides, setSlides] = useState(null); // Store dynamically imported slides

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        // Dynamically import the HomeSlider data
        const { HomeSlider } = await import("./../../Data/SliderImages");
        setSlides(HomeSlider); // Set the loaded slides
      } catch (error) {
        console.error("Error loading HomeSlider data:", error);
      } finally {
        setLoading(false); // Loading complete
      }
    };

    fetchSlides(); // Trigger the data fetch
  }, []);

  if (loading) {
    return <Loading />; // Render loading indicator while loading
  }

  if (!slides || slides.length === 0) {
    return <div>No slides available</div>; // Fallback in case of no data
  }

  return (
    <Swiper
      className="home-swiper"
      autoplay={{ delay: 4000 }}
      effect="fade"
      pagination={{
        clickable: true,
        type: "bullets",
      }}
      style={{
        "--swiper-pagination-bullet-active": "#FFFFFF",
        "--swiper-pagination-bullet-active-color": "#ff0000",
      }}
      modules={[EffectFade, Autoplay, Pagination]}
      loop={true}
    >
      { Dynamically render SwiperSlides }
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className="home-swiper-slide">
          <img
            src={slide.image}
            alt={slide.title}
            loading="lazy"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Home;
*/


/*import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./Home.css";
import { useParams } from "react-router-dom";

function Home({ slides }) {
  const { ProductId } = useParams();
  const productIndex = parseInt(ProductId, 10); // Ensure it's a number

  console.log(slides[productIndex]); // Debug ProductId and data

  return (
    <Swiper
      className="home-swiper"
      autoplay={{ delay: 4000 }}
      effect="fade"
      pagination={{
        clickable: true,
        type: "bullets",
      }}
      style={{
        "--swiper-pagination-bullet-active": "#FFFFFF",
        "--swiper-pagination-bullet-active-color": "#ff0000",
      }}
      modules={[EffectFade, Autoplay, Pagination]}
      loop={true}
    >
      
        <SwiperSlide className="home-swiper-slide">
          <img
            src="/images/HomeSlider/HisteriaMaxBG.jpg"
            alt="Histeria Max"
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="home-swiper-slide">
          <img
            src="/images/HomeSlider/HugyMooshDarkBG.jpg"
            alt="Histeria Max"
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="home-swiper-slide">
          <img
            src="/images/HomeSlider/HugyMooshWhiteBG.jpg"
            alt="Histeria Max"
            loading="lazy"
          />
        </SwiperSlide>

    </Swiper>
  );
}

export default Home;
*/



/*
<div className="scrollDown" onClick={scrollToElement}>
<span></span>
<span></span>
<span></span>
</div>
*/

/*

import React, { useEffect } from "react";
import "./Home.css";
import { MdLocalPhone } from "react-icons/md";
import Aos from "aos";
import "aos/dist/aos.css";

function Home() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  function scrollToElement() {
    const targetElement = document.getElementById("main");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <div>
      <section id="home" className="home">
        <div className="overlay"></div>
        <img
          src="/images/ch1.jpg"
          alt="wallpaper"
          className="imgWallpaper"
        ></img>

        <div className="homeContent container">
          <div className="textDiv">
            <span data-aos="fade-up" className="smallText">
              Live Your Dream
            </span>
            <h1 data-aos="fade-up" className="homeTitle">
              In Search Of Incredible
            </h1>
          </div>

          <div data-aos="zoom-in-right" className="homeFooterIcons flex">
            <div className="rightIcons">
              <MdLocalPhone className="icon" />
              <p className="number">+96333448412</p>
            </div>
            <div className="rightIcons">
              <MdLocalPhone className="icon" />
              <p className="number">+96333448412</p>
            </div>
          </div>
        </div>
        <div className="scrollDown" onClick={scrollToElement}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
    </div>
  );
}

export default Home;


  <Swiper
    className="home-swiper"
    key={item.id}
    autoplay={{
      delay: 2000,
    }}
    effect="fade"
    speed={1000}
    modules={[EffectFade, Autoplay]}
    loop={true}
  >
    <SwiperSlide className="home-swiper-slide">
      <img src={item.home_image} alt={item.home_image} />
      <div className="slide-text">{item.home_title}</div>
    </SwiperSlide>
  </Swiper>



*/